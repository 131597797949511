/*
  Name:       Moxer Theme
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://github.com/moxer-theme/moxer-code
*/

.cm-s-moxer.CodeMirror {
  background-color: #090A0F;
  color: #8E95B4;
  line-height: 1.8;
}

.cm-s-moxer .CodeMirror-gutters {
  background: #090A0F;
  color: #35394B;
  border: none;
}

.cm-s-moxer .CodeMirror-guttermarker,
.cm-s-moxer .CodeMirror-guttermarker-subtle,
.cm-s-moxer .CodeMirror-linenumber {
  color: #35394B;
}


.cm-s-moxer .CodeMirror-cursor {
  border-left: 1px solid #FFCC00;
}

.cm-s-moxer div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-moxer.CodeMirror-focused div.CodeMirror-selected {
  background: #212431;
}

.cm-s-moxer .CodeMirror-line::selection,
.cm-s-moxer .CodeMirror-line>span::selection,
.cm-s-moxer .CodeMirror-line>span>span::selection {
  background: #212431;
}

.cm-s-moxer .CodeMirror-line::-moz-selection,
.cm-s-moxer .CodeMirror-line>span::-moz-selection,
.cm-s-moxer .CodeMirror-line>span>span::-moz-selection {
  background: #212431;
}

.cm-s-moxer .CodeMirror-activeline-background,
.cm-s-moxer .CodeMirror-activeline-gutter .CodeMirror-linenumber {
  background: rgba(33, 36, 49, 0.5);
}

.cm-s-moxer .cm-keyword {
  color: #D46C6C;
}

.cm-s-moxer .cm-operator {
  color: #D46C6C;
}

.cm-s-moxer .cm-variable-2 {
  color: #81C5DA;
}


.cm-s-moxer .cm-variable-3,
.cm-s-moxer .cm-type {
  color: #f07178;
}

.cm-s-moxer .cm-builtin {
  color: #FFCB6B;
}

.cm-s-moxer .cm-atom {
  color: #A99BE2;
}

.cm-s-moxer .cm-number {
  color: #7CA4C0;
}

.cm-s-moxer .cm-def {
  color: #F5DFA5;
}

.cm-s-moxer .CodeMirror-line .cm-def ~ .cm-def {
  color: #81C5DA;
}

.cm-s-moxer .cm-string {
  color: #B2E4AE;
}

.cm-s-moxer .cm-string-2 {
  color: #f07178;
}

.cm-s-moxer .cm-comment {
  color: #3F445A;
}

.cm-s-moxer .cm-variable {
  color: #8E95B4;
}

.cm-s-moxer .cm-tag {
  color: #FF5370;
}

.cm-s-moxer .cm-meta {
  color: #FFCB6B;
}

.cm-s-moxer .cm-attribute {
  color: #C792EA;
}

.cm-s-moxer .cm-property {
  color: #81C5DA;
}

.cm-s-moxer .cm-qualifier {
  color: #DECB6B;
}

.cm-s-moxer .cm-variable-3,
.cm-s-moxer .cm-type {
  color: #DECB6B;
}


.cm-s-moxer .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #FF5370;
}

.cm-s-moxer .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}